
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Divider',
  props: {
    hasTopSpacing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
})
