

import { storage } from '@/firebase'
import {
  ref, defineComponent, onMounted,
} from 'vue'

const storageRef = storage.ref()

export default defineComponent({
  props: {
    name: String,
    storagePath: String,
    mimeType: {
      type: String,
      required: false,
      defualt: 'image/*',
    },
    data: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const preview = ref<string | undefined>(undefined)
    onMounted(async () => {
      if (props.data) {
        preview.value = await storageRef.child(props.data).getDownloadURL()
      }
    })
    const onImageSelected = async (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.files?.length) {
        preview.value = undefined
        const now = Date.now()
        const imageRef = storageRef.child(`${ props.storagePath }/${ now }.png`)
        // 1. Upload to storage
        const snapshot = await imageRef.put(target.files[0])
        // 2. Emit change to model
        emit('update:data', snapshot.ref.fullPath)
        preview.value = await imageRef.getDownloadURL()
      }
    }

    return {
      onImageSelected,
      preview,
    }
  },
})
