
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, onMounted, ref, defineComponent,
  nextTick,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import ImageUpload from '@/components/Form/ImageUpload.vue'
import { IHubAccount } from '@/types'
import Divider from '@/components/Form/Divider.vue'

export default defineComponent({
  components: {
    InputField,
    Button,
    ImageUpload,
    Divider,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isNew = computed(() => route.name === 'HubAccountNew')
    const data = ref<{account: IHubAccount}>()
    const isLoading = ref(true)
    const successMessage = ref(false)
    const failMessage = ref<string>()
    const urlIsDirty = ref<boolean>(false)
    const { isAdmin } = store.getters

    onMounted(() => {
      data.value = {
        account: {
          id: '',
          url: '',
          name: '',
          logo: '',
          createdAt: '',
          contactUserID: '',
          isPrivate: true,
        },

      }
      if (!isNew.value) {
        const existingAccount = store.getters['hubStore/accounts'].find(
          (account: IHubAccount) => account.id === route.params.accountID,
        )
        urlIsDirty.value = true
        data.value.account = {
          ...existingAccount,
        }
      }
      // If account is not found redirect to create new account
      if (!isNew.value && !data.value.account.id) {
        router.push({ name: 'HubAccountNew' })
      }

      isLoading.value = false
    })

    const onSubmit = () => {
      if (isLoading.value) {
        return
      }
      failMessage.value = undefined
      isLoading.value = true

      if (isNew.value) {
        // If new - create account
        store.dispatch('hubStore/createAccount', {
          data: {
            account: data.value?.account,
          },
        })
          .then(({ data }) => {
            const { accountID } = data
            router.push({ name: 'HubAccount', params: { accountID } })
            isLoading.value = false
          })
          .catch((error) => {
            failMessage.value = error.message
            isLoading.value = false
            console.log(error)
          })
      }
      else {
        // If existing - update account
        store.dispatch('hubStore/updateAccount', {
          accountData: data.value?.account,
          accountID: route.params.accountID,
        })
          .then(() => {
            isLoading.value = false
            successMessage.value = true
            setTimeout(() => {
              successMessage.value = false
            }, 1000)
          })
          .catch((error) => {
            isLoading.value = false
            console.log(error)
          })
      }
    }
    function slugify(str: string) {
      str = str.replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
      str = str.toLowerCase() // convert string to lowercase
      str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-') // remove consecutive hyphens
      return str
    }
    function generateUrl() {
      if (!data.value || urlIsDirty.value) {
        return
      }
      data.value.account.url = slugify(data.value?.account.name)
    }
    function updateAccountURL(val:string) {
      urlIsDirty.value = true
      if (!data.value) {
        return
      }
      // The url is emptied first, to ensure the input fields value is updated
      data.value.account.url = ''
      nextTick(() => {
        if (!data.value) {
          return
        }
        data.value.account.url = slugify(val)
      })
    }

    return {
      updateAccountURL,
      generateUrl,
      slugify,
      isAdmin,
      onSubmit,
      data,
      isLoading,
      isNew,
      successMessage,
      failMessage,
    }
  },
})

