
import { useRoute } from 'vue-router'
import {
  computed, defineComponent,
} from 'vue'
import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import HubAccountForm from '@/components/Forms/HubAccountForm.vue'
import { useStore } from 'vuex'
import { firebase } from '@/firebase'
import { IAccount } from '@/types'
import PageTop from '@/components/PageTop.vue'

export default defineComponent({
  components: {
    Page,
    Box,
    HubAccountForm,
    PageTop,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const isNew = computed(() => route.name === 'HubAccountNew')
    const account = computed<IAccount | undefined>(
      () => route.params.accountID && store.getters['hubStore/getAccountByID'](route.params.accountID),
    )

    return {
      isNew,
      account,
    }
  },
})

