import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "SuccessMessage"
}
const _hoisted_2 = {
  key: 1,
  class: "FailMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")
  const _component_ImageUpload = _resolveComponent("ImageUpload")
  const _component_InputField = _resolveComponent("InputField")
  const _component_Button = _resolveComponent("Button")

  return (_ctx.data)
    ? (_openBlock(), _createBlock("form", {
        key: 0,
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        ref: "form"
      }, [
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Konto')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ImageUpload, {
          label: "logo",
          storagePath: `HubAccountLogos/${_ctx.data.account.id}`,
          mimeType: "image/png",
          data: _ctx.data.account.logo,
          "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => (_ctx.data.account.logo = $event)),
          name: "mainLogo"
        }, null, 8, ["storagePath", "data"]),
        _createVNode(_component_InputField, {
          name: "name",
          label: "Navn",
          data: _ctx.data.account.name,
          "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.account.name = $event)),
          required: "",
          onFieldInput: _ctx.generateUrl
        }, null, 8, ["data", "onFieldInput"]),
        _createVNode(_component_InputField, {
          name: "url",
          label: "URL",
          data: _ctx.data.account.url,
          "onUpdate:data": _ctx.updateAccountURL,
          required: ""
        }, null, 8, ["data", "onUpdate:data"]),
        _createVNode(_component_Button, {
          disabled: _ctx.isLoading || !_ctx.isAdmin,
          type: "submit",
          label: _ctx.isNew? 'Submit':'Update'
        }, null, 8, ["disabled", "label"]),
        (_ctx.successMessage)
          ? (_openBlock(), _createBlock("div", _hoisted_1, "Account saved"))
          : _createCommentVNode("", true),
        (_ctx.failMessage)
          ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.failMessage), 1))
          : _createCommentVNode("", true)
      ], 544))
    : _createCommentVNode("", true)
}